import { Box, Grid, MenuItem, Paper, Typography } from "@mui/material"
import { VTextField } from "../../../../shared/forms"
import { AutoCompleteBank } from "../../../../shared/components";

interface IBankClientProps {
    isLoading: boolean;
}

const due_date = Array.from({ length: 30 }, (_, index) => index + 1);

export const BankClient: React.FC<IBankClientProps> = ({
    isLoading
}) => {

    return (
        <Box margin={1} display="flex" flexDirection="column" component={Paper}  variant="elevation" elevation={0}>
            <Grid container direction="column" padding={2} spacing={2}>
                <Grid container item direction="row" spacing={2}>
                    <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                        <Typography variant="h6" color={'primary'}>Dados Bancários</Typography>
                    </Grid>
                </Grid>

                <Grid container item direction="row" spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <AutoCompleteBank
                            isExternalLoading={isLoading} labelSelect={'Banco'} namecampo={'bank_code'}
                        />

                    </Grid>
                    <Grid item xs={10} sm={10} md={3} lg={3} xl={3}>
                        <VTextField
                            fullWidth
                            label='Agência'
                            name="agency_number"
                            disabled={isLoading}
                        />
                    </Grid>
                    <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                        <VTextField
                            fullWidth
                            label='Digito'
                            name="agency_digit"
                            disabled={isLoading}
                        />
                    </Grid>
                    <Grid item xs={10} sm={10} md={3} lg={3} xl={3}>
                        <VTextField
                            fullWidth
                            label='Conta'
                            name="account_number"
                            disabled={isLoading}
                        />
                    </Grid>
                    <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                        <VTextField
                            fullWidth
                            label='Digito'
                            name="account_digit"
                            disabled={isLoading}
                        />
                    </Grid>
                </Grid>

                <Grid container item direction="row" spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <VTextField
                            fullWidth
                            label='Tipo de Pix'
                            name="pix_key_type"
                            disabled={isLoading}
                            select
                        >
                            <MenuItem value={'CPF'}>CPF/CNPJ</MenuItem>
                            <MenuItem value={'celular'}>Celular</MenuItem>
                            <MenuItem value={'email'}>E-mail</MenuItem>
                            <MenuItem value={'aleatoria'}>Aleatória</MenuItem>
                        </VTextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <VTextField
                            fullWidth
                            label='Chave Pix'
                            name="pix_key"
                            disabled={isLoading}
                        />
                    </Grid>
                </Grid>
                <Grid container item direction="row" spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <VTextField
                            fullWidth
                            label='Responsavel da conta'
                            name="bank_manager"
                            disabled={isLoading}
                        >
                        </VTextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <VTextField
                            fullWidth
                            label='Dia vencimento fatura'
                            name="invoice_due_date"
                            disabled={isLoading}
                            select
                        >

                            {due_date.map((index) => {
                                return (
                                    <MenuItem key={index} value={index.toString()}>
                                        {index.toString()}
                                    </MenuItem>
                                );
                            })}
                        </VTextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <VTextField
                            fullWidth
                            label='Gerar Boleto de Pagamento'
                            name="invoiceGateway"
                            disabled={isLoading}
                            select
                        >
                            <MenuItem value={'PJBANK'}>PJBANK</MenuItem>
                            <MenuItem value={'SICOOB'}>SICOOB</MenuItem>
                        </VTextField>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
};