import * as yup from "yup";

export interface IFormData {
	document: string;
	status: string;
	email: string;
	name: string;
	trade_name: string;
	responsible_name: string;
	responsible_document?: string;
	phone: string;
	address_zipcode: string;
	address_street: string;
	address_number: string;
	address_district: string;
	address_city: string;
	address_state: string;
	address_ibge?: string;
	bank_manager?: string;
	bank_code?: string;
	agency_number?: string;
	agency_digit?: string;
	account_number?: string;
	invoice_due_date: number;
	invoiceGateway?: string;
	mobilePhone?: string;
	observationPartner?: string;
	origem?: string;
	typeId: string;
	baseRateCalc?: string;
	activeSignature?: boolean;
	signContract?: string;
	tax_setup_enable?: boolean;
	tax_setup_limit_default?: string;
	tax_setup_limit_min?: string;
	tax_setup_limit_max?: string;
	bonusActive?: string;
	comissionActive?: string;
	creditCard?: string;
}

export function getClientValidationSchema(isCreate: boolean): yup.SchemaOf<IFormData> {
	return yup.object().shape({
		document: yup.string().min(11).required("Campo Obrigatório"),
		typeId: yup.string().required("Campo Obrigatório"),
		status: yup.string().required("Campo Obrigatório"),
		email: yup.string().min(3).required("Campo Obrigatório"),
		creci: yup.string().nullable(),
		name: yup.string().min(3).required("Campo Obrigatório"),
		trade_name: yup.string().required("Campo Obrigatório"),
		responsible_name: yup.string().required("Campo Obrigatório"),
		responsible_document: isCreate ? yup.string().required("Campo Obrigatório") : yup.string().notRequired(),

		phone: yup.string().min(10).required("Campo Obrigatório"),
		address_zipcode: yup.string().required("Campo Obrigatório"),
		address_street: yup.string().required("Campo Obrigatório"),
		address_number: yup.string().required("Campo Obrigatório"),
		address_complement: yup.string().nullable(),
		address_district: yup.string().required("Campo Obrigatório"),
		address_city: yup.string().required("Campo Obrigatório"),
		address_state: yup.string().required("Campo Obrigatório"),
		address_ibge: yup.string().notRequired(),

		bank_manager: yup.string().required("Campo Obrigatório").nullable(),
		bank_code: yup.string().required("Campo Obrigatório").nullable(),
		agency_number: yup.string().required("Campo Obrigatório").nullable(),
		agency_digit: yup.string().required("Campo Obrigatório").nullable(),
		account_number: yup.string().required("Campo Obrigatório").nullable(),

		invoice_due_date: yup.number().required("Campo Obrigatório"),
		invoiceGateway: yup.string().required("Campo Obrigatório"),

		mobilePhone: yup.string().notRequired(),
		observationPartner: yup.string().optional(),
		origem: yup.string().optional(),
		baseRateCalc: yup.string().notRequired(),
		activeSignature: yup.boolean().notRequired(),
		signContract: yup.string().notRequired(),
		tax_setup_enable: yup.boolean().notRequired(),
		tax_setup_limit_default: yup.string().notRequired(),
		tax_setup_limit_min: yup.string().notRequired(),
		tax_setup_limit_max: yup.string().notRequired(),
		bonusActive: yup.string().notRequired(),
		comissionActive: yup.string().notRequired(),
		creditCard: yup.string().notRequired(),
	});
}
