import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Backdrop, CircularProgress } from "@mui/material";
import logo from "../../../assets/logo_garantti.png";

import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

import Information from "./Information";
import getCheckoutTheme from "./getCheckoutTheme";
import PaymentForm from "./PaymentForm";
import PaymentSuccess from "./PaymentSuccess";
import { useAlert } from "./AlertContext";
import { useParams } from "react-router-dom";
import axios from "axios";
import { PaymentNotFound } from "./PaymentNotFound";

interface ToggleCustomThemeProps {
	showCustomTheme: Boolean;
	toggleCustomTheme: () => void;
}

const logoStyle = {
	width: "90px",
	"object-fit": "cover",
	height: "100%",
	marginLeft: "-4px",
	marginRight: "-8px",
};

interface IPaymentData {
	cardToken: string;
	cvv: string;
	holderName: string;
	expirationDate: string;
	isFormValid: boolean;
	installments: string;
}

function getStepContent(step: number, data: any, onChangeData: (data: IPaymentData) => void) {
	switch (step) {
		case 0:
			return <Information data={data} />;
		case 1:
			return <PaymentForm amount={data?.transaction?.setupAmount} onChangeData={onChangeData} />;
		case 2:
			return <PaymentSuccess />;
		default:
			throw new Error("Unknown step");
	}
}

export default function Checkout() {
	const mode = "light";

	const checkoutTheme = createTheme(getCheckoutTheme(mode));
	const [activeStep, setActiveStep] = React.useState(0);
	const [paymentData, setPaymentData] = React.useState<IPaymentData>({} as IPaymentData);
	const [success, setSuccess] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [steps, setSteps] = React.useState(["Informações", "Pagamento", "Confirmação"]);

	const [data, setData] = React.useState<any>();

	const { id } = useParams<{ id: string }>();

	const { showAlert } = useAlert();

	React.useEffect(() => {
		setIsLoading(true);
		async function loadPaymentData() {
			const config = {
				method: "GET",
				url: `${process.env.REACT_APP_BASE_API}transaction/${id}`,
			};
			const response = await axios(config);
			const { status } = response.data;

			if (status !== "pending") {
				setSteps(["Informações"]);
			}

			setData(response.data);
			setIsLoading(false);
		}
		loadPaymentData();
	}, [id]);

	const onChangeData = (data: any) => {
		setPaymentData(data);
	};

	const handlePayment = async () => {
		if (!paymentData) {
			return;
		}

		try {
			setIsLoading(true);
			const { cardToken, cvv, holderName, expirationDate, installments } = paymentData;

			const expirationDateArray = expirationDate.split("/");
			const month = expirationDateArray[0];
			const year = expirationDateArray[1];

			const data = {
				holderName,
				month,
				year: `20${year}`,
				cvv,
				creditCardToken: cardToken,
				installments,
			};
			const config = {
				method: "post",
				url: `${process.env.REACT_APP_BASE_API}/transaction/setup/payment/${id}`,
				data: data,
			};

			const response = await axios(config);

			if (response.data.success) {
				setSuccess(true);
				handleNext();
			} else {
				showAlert("warning", "Não foi possível realizar o pagamento");
			}
			setIsLoading(false);
		} catch (error) {
			showAlert("warning", "Não foi possível realizar o pagamento");
			setIsLoading(false);
		}
	};

	const handleNext = () => {
		setActiveStep(activeStep + 1);
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);
	};

	if (!data) {
		return null;
	}

	return (
		<ThemeProvider theme={checkoutTheme}>
			<CssBaseline />
			<Grid container sx={{ justifyContent: "center", height: { xs: "100%", sm: "100dvh" } }}>
				{data.status === "notfound" ? (
					<PaymentNotFound />
				) : (
					<Grid
						item
						sm={12}
						md={7}
						lg={8}
						sx={{
							display: "flex",
							flexDirection: "column",
							maxWidth: "100%",
							width: "100%",
							backgroundColor: {
								xs: "transparent",
								sm: "background.default",
							},
							alignItems: "center",
							pt: { xs: 2, sm: 4 },
							px: { xs: 2, sm: 10 },
							gap: { xs: 4, md: 8 },
						}}
					>
						<Grid
							container
							sm={12}
							md={12}
							lg={12}
							sx={{
								// border: '1px solid divider',

								padding: 2,
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								gap: { xs: 4, md: 8 },
								backgroundColor: "background.paper",
								maxWidth: { sm: "100%", md: 600 },
							}}
						>
							<Box
								sx={{
									display: "flex",
									justifyContent: { sm: "space-between", md: "flex-end" },
									alignItems: "center",
									width: "100%",
									maxWidth: { sm: "100%", md: 600 },
								}}
							>
								<Box
									sx={{
										display: { xs: "flex", md: "none" },
										flexDirection: "row",
										width: "100%",
										justifyContent: "center",
										backgroundColor: "primary.main",
									}}
								>
									<img src={logo} alt="Logo" style={logoStyle} />
								</Box>
								<Box
									sx={{
										display: { xs: "none", md: "flex" },
										flexDirection: "column",
										justifyContent: "space-between",
										alignItems: "center",
										backgroundColor: "primary.main",
										flexGrow: 1,
										height: 90,
										gap: 2,
									}}
								>
									<img src={logo} alt="Logo" style={logoStyle} />

									<Stepper
										id="desktop-stepper"
										activeStep={success ? steps.length : activeStep}
										sx={{
											width: "100%",
											height: 40,
										}}
									>
										{steps.map((label) => (
											<Step
												sx={{
													":first-child": { pl: 0 },
													":last-child": { pr: 0 },
												}}
												key={label}
											>
												<StepLabel>{label}</StepLabel>
											</Step>
										))}
									</Stepper>
								</Box>
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									flexGrow: 1,
									width: "100%",
									maxWidth: { sm: "100%", md: 600 },
									maxHeight: "720px",
									gap: { xs: 5, md: "none" },
								}}
							>
								<Stepper
									id="mobile-stepper"
									activeStep={success ? steps.length : activeStep}
									alternativeLabel
									sx={{ display: { sm: "flex", md: "none" } }}
								>
									{steps.map((label) => (
										<Step
											sx={{
												":first-child": { pl: 0 },
												":last-child": { pr: 0 },
												"& .MuiStepConnector-root": { top: { xs: 6, sm: 12 } },
											}}
											key={label}
										>
											<StepLabel
												sx={{
													".MuiStepLabel-labelContainer": { maxWidth: "70px" },
												}}
											>
												{label}
											</StepLabel>
										</Step>
									))}
								</Stepper>

								{
									<React.Fragment>
										{getStepContent(activeStep, data, onChangeData)}
										<Box
											sx={{
												display: "flex",
												flexDirection: { xs: "column-reverse", sm: "row" },
												justifyContent: activeStep !== 0 ? "space-between" : "flex-end",
												alignItems: "end",
												flexGrow: 1,
												gap: 1,
												pb: { xs: 12, sm: 0 },
												mt: { xs: 2, sm: 0 },
												mb: "60px",
											}}
										>
											{activeStep !== 0 && activeStep !== 2 && (
												<Button
													startIcon={<ChevronLeftRoundedIcon />}
													onClick={handleBack}
													variant="text"
													sx={{
														display: { xs: "none", sm: "flex" },
													}}
												>
													Anterior
												</Button>
											)}

											{activeStep !== 0 && activeStep !== 2 && (
												<Button
													startIcon={<ChevronLeftRoundedIcon />}
													onClick={handleBack}
													variant="outlined"
													fullWidth
													sx={{
														display: { xs: "flex", sm: "none" },
													}}
												>
													Anterior
												</Button>
											)}
											{activeStep <= steps.length - 2 && (
												<Button
													variant="contained"
													color={activeStep === steps.length - 2 && paymentData.isFormValid ? "primary" : "info"}
													endIcon={<ChevronRightRoundedIcon />}
													disabled={activeStep === steps.length - 2 && !paymentData.isFormValid}
													onClick={activeStep === steps.length - 2 ? handlePayment : handleNext}
													sx={{
														width: { xs: "100%", sm: "fit-content" },
													}}
												>
													{activeStep === steps.length - 2 ? "Confirmar Pagamento" : "Próximo"}
												</Button>
											)}
										</Box>
									</React.Fragment>
								}
							</Box>
						</Grid>
					</Grid>
				)}
			</Grid>
			<Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</ThemeProvider>
	);
}
