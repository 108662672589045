import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { clientService, IDetalheCliente } from "../services/api/client/clientService";
import { maskPhone } from "../utils";

interface UseClientDetailsProps {
	id: string;
	formRef: any;
	setNome: (nome: string) => void;
	setInitialStates: (row: IDetalheCliente) => void;
}

export function useClientDetails({ id, formRef, setNome, setInitialStates }: UseClientDetailsProps) {
	const [isLoading, setIsLoading] = useState(false);
	const [row, setRow] = useState<IDetalheCliente>();
	const navigate = useNavigate();

	useEffect(() => {
		if (id !== "novo") {
			setIsLoading(true);

			clientService.getByID(id).then((result) => {
				setIsLoading(false);

				if (result instanceof Error) {
					alert(result.message);
					navigate("/clientes");
				} else {
					setNome(result.name);

					formRef.current?.setData({
						...result,
						phone: result.phone ? maskPhone(result.phone) : "",
						mobilePhone: result.mobilePhone ? maskPhone(result.mobilePhone) : "",
						origem: result.created_from === "web_app" ? "Web APP" : "Web Service",
					});

					setInitialStates(result);
					setRow(result);
				}
			});
		}
	}, [id, navigate]);

	return { isLoading, row };
}
