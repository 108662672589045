import { Alert } from "@material-ui/lab";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface IProps {
	data: any;
}

const getPaymentStatus = (status: string) => {
	switch (status) {
		case "canceled":
			return (
				<Alert variant="outlined" severity="error">
					Esse pagamento foi cancelado
				</Alert>
			);
		case "processing":
			return (
				<Alert variant="outlined" severity="warning">
					Esse pagamento está sendo processado
				</Alert>
			);
		case "paid":
			return (
				<Alert variant="outlined" severity="success">
					Esse pagamento já foi efetuado
				</Alert>
			);
		default:
			return null;
	}
};
export default function Information({ data }: IProps) {
	return (
		<Stack spacing={2}>
			{getPaymentStatus(data.status)}
			<List disablePadding>
				<ListItem sx={{ py: 1, px: 0 }}>
					<ListItemText primary="Imobiliária" secondary={data?.transaction?.partnerName} />
				</ListItem>
				<ListItem sx={{ py: 1, px: 0 }}>
					<ListItemText primary="Cliente" secondary={data?.transaction?.name} />
				</ListItem>
				<ListItem sx={{ py: 1, px: 0 }}>
					<ListItemText primary="CPF" secondary={data?.transaction?.document} />
				</ListItem>
				<ListItem sx={{ py: 1, px: 0 }}>
					<ListItemText primary="Endereço do Imóvel" secondary={data?.transaction?.address} />
				</ListItem>
				<ListItem sx={{ py: 1, px: 0 }}>
					<ListItemText primary="Valor do Aluguel" />
					<Typography variant="body2" color="warning.main">
						{data?.transaction?.rentAmount && `R$ ${data?.transaction?.rentAmount}`}
					</Typography>
				</ListItem>
				<ListItem sx={{ py: 1, px: 0 }}>
					<ListItemText primary="Taxa de Setup" />
					<Typography variant="body2" color="secondary">
						{data?.transaction?.setupAmount && `R$ ${data?.transaction?.setupAmount}`}
					</Typography>
				</ListItem>
			</List>
			<Divider />
		</Stack>
	);
}
