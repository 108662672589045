import { Avatar, Box, Card, Typography } from "@mui/material";
import { format } from "date-fns";
import React from "react";

interface FollowUpMessageCardProps {
	note: {
		id: string;
		note: string;
		userName: string;
		userId: string;
		createdAt: string;
	};
	isMine: boolean;
	loggedUserName: string;
}

export const FollowUpMessageCard: React.FC<FollowUpMessageCardProps> = ({ note, isMine, loggedUserName }) => {
	const getInitials = (name: string) => {
		const parts = name.trim().split(" ");
		if (parts.length === 1) return parts[0].charAt(0).toUpperCase();
		return (parts[0].charAt(0) + parts[parts.length - 1].charAt(0)).toUpperCase();
	};
	const isHtml = (content: string): boolean => /<\/?[a-z][\s\S]*>/i.test(content);

	return (
		<Box display="flex" justifyContent={isMine ? "flex-end" : "flex-start"}>
			<Box display="flex" alignItems="flex-start" gap={1} maxWidth="80%">
				{!isMine && <Avatar sx={{ width: 32, height: 32, mr: 1 }}>{getInitials(note.userName)}</Avatar>}

				<Box sx={{ position: "relative", display: "inline-block" }}>
					<Card
						sx={{
							backgroundColor: isMine ? "#e7ffe7" : "white",
							color: "black",
							borderRadius: 5,
							padding: "8px 20px",
							maxWidth: "100%",
							minWidth: "200px",
							boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
						}}
					>
						<Typography variant="subtitle2" sx={{ fontWeight: 600, mb: 0.25 }}>
							{note.userName}
						</Typography>

						{isHtml(note.note) ? (
							<Box
								sx={{
									fontSize: "0.875rem",
									color: "inherit",
									fontFamily: "roboto, sans-serif",
									"& p": { margin: 0, marginBottom: "0.5em" },
								}}
								dangerouslySetInnerHTML={{ __html: note.note }}
							/>
						) : (
							<Typography variant="body2" sx={{ whiteSpace: "pre-line", mb: 0.25 }}>
								{note.note}
							</Typography>
						)}

						<Typography variant="caption" color="text.secondary" sx={{ mt: 0.25, display: "block", textAlign: "right" }}>
							{format(new Date(note.createdAt), "dd/MM/yyyy HH:mm")}
						</Typography>
					</Card>

					{/* Orelha */}
					<Box
						sx={{
							position: "absolute",
							top: 12,
							...(isMine
								? {
										right: -10,
										borderWidth: "10px 0 10px 10px",
										borderStyle: "solid",
										borderColor: "transparent transparent transparent #e7ffe7",
								  }
								: {
										left: -10,
										borderWidth: "10px 10px 10px 0",
										borderStyle: "solid",
										borderColor: "transparent white transparent transparent",
								  }),
						}}
					/>
				</Box>

				{isMine && <Avatar sx={{ width: 32, height: 32, ml: 1 }}>{getInitials(loggedUserName)}</Avatar>}
			</Box>
		</Box>
	);
};
