import { ClientFollowUpService } from "../../../../shared/services/api/client/clientFollowUpService";
import { ProposalCancellingService } from "../../../../shared/services/api/proposal/ProposalCancellingService";
import { SinisterFollowUpService } from "../../../../shared/services/api/sinister/SinisterFollowUpService";
import { FollowUpServices } from "./GenericFollowUpModalContent";

export type FollowUpContext = "client" | "sinister" | "canceledProposal";

export const getFollowUpServiceByContext = (context: FollowUpContext): FollowUpServices => {
	switch (context) {
		case "client":
			return {
				getNotes: (id: string) => ClientFollowUpService.getClientFollowUpNotes(id),
				addNote: (id: string, message: string): Promise<void> => {
					return ClientFollowUpService.addClientFollowUpNote(id, message).then(() => {});
				},
				getContacts: (id: string) => ClientFollowUpService.getClientFollowUpContacts(id),
				addContact: (id: string, email: string) => ClientFollowUpService.addClientFollowUpContact(id, email),
				removeContact: (contactId: string) => ClientFollowUpService.removeClientFollowUpContact(contactId),
			};

		case "sinister":
			return {
				getNotes: (id: string) => SinisterFollowUpService.getSinisterFollowupNotes(id),
				addNote: (id: string, message: string): Promise<void> => {
					return SinisterFollowUpService.addSinisterFollowupNote(id, message).then(() => {});
				},
				getContacts: (id: string) => SinisterFollowUpService.getSinisterFollowupContacts(id),
				addContact: (id: string, email: string) => SinisterFollowUpService.addSinisterFollowupContact(id, email),
				removeContact: (contactId: string) => SinisterFollowUpService.removeSinisterFollowupContact(contactId),
			};

		case "canceledProposal":
			return {
				
				getNotes: (id: string) => ProposalCancellingService.getProposalCancellingFollowupNotes(id),
				addNote: (id: string, message: string): Promise<void> => {
					return ProposalCancellingService.addProposalCancellingFollowupNote(id, message).then(() => {});
				},
				getContacts: (id: string) => ProposalCancellingService.getProposalCancellingFollowupContacts(id),
				addContact: (id: string, email: string) => ProposalCancellingService.addProposalCancellingFollowupContact(id, email),
				removeContact: (contactId: string) => ProposalCancellingService.removeProposalCancellingFollowupContact(contactId),
			};

		default:
			throw new Error(`Contexto de FollowUp desconhecido: ${context}`);
	}
};
