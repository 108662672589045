import React from "react";
import { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, CircularProgress, Typography } from "@mui/material";
import { useDebounce, useToast } from "../../../../shared/hooks";
import { clientService, IListagemCliente } from "../../../../shared/services/api/client/clientService";
import { ToastContent } from "../../../../shared/components";
import { returnMessageError } from "../../../../shared/utils/returnMessageError";

interface PartnersCardProps {
	id: string;
}

export const PartnersCard: React.FC<PartnersCardProps> = ({ id }) => {
	const [partners, setPartners] = useState<IListagemCliente[]>([]);
	const [Isloading, setIsLoading] = useState<boolean>(true);
	const { debounce } = useDebounce();
	const toastHandler = useToast();

	const getPartnersByClient = () => {
		if (id !== "novo") {
			setIsLoading(true);
			debounce(() => {
				clientService
					.getPartners(id)
					.then((result) => {
						setIsLoading(false);
						if (result instanceof Error) {
							const titleMessage = "Alerta!!";
							const contentMessage = "Erro: " + result?.message;

							toastHandler.present({
								type: "error",
								position: "top-right",
								messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
							});
						} else setPartners(result.data);
					})
					.catch((err: any) => {
						setIsLoading(false);
						const { titleMessage, contentMessage } = returnMessageError(err);
						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
						});
					});
			});
		}
	};

	useEffect(() => {
		getPartnersByClient();
	}, [id]);

	return (
		<TableContainer
			component={Paper}
			variant="outlined"
			sx={{
				marginLeft: 1,
				marginTop: 1,
				marginRight: 2,
				width: "auto",
				padding: 4,
				overflow: "auto",
			}}
			aria-label="customized table"
		>
			<Table aria-label="a dense table">
				<TableRow>
					<TableCell sx={{ color: "#7e7777b5" }}> Nome</TableCell>
					<TableCell sx={{ color: "#7e7777b5" }}> E-mail</TableCell>
					<TableCell sx={{ color: "#7e7777b5" }}> Status</TableCell>
				</TableRow>
				<TableBody>
					{Isloading ? (
						<TableRow>
							<TableCell sx={{ textAlign: "center" }} colSpan={4}>
								<CircularProgress />
							</TableCell>
						</TableRow>
					) : !partners?.length ? (
						<TableRow>
							<TableCell sx={{ textAlign: "center" }} colSpan={4}>
								Nenhum parceiro encontrado!
							</TableCell>
						</TableRow>
					) : (
						partners?.map((partner, index) => (
							<TableRow key={index} hover>
								<TableCell sx={{ fontWeight: "normal" }}>
									<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
										{partner.name}
									</Typography>
								</TableCell>
								<TableCell sx={{ fontWeight: "normal" }}>
									<Typography variant="subtitle2" fontWeight={"normal"}>
										{partner.email}
									</Typography>
								</TableCell>
								<TableCell sx={{ fontWeight: "normal" }}>
									<Typography variant="subtitle2" fontWeight={"normal"} textAlign={"center"}>
										{partner.status === "active" ? "Ativo" : "Inativo"}
									</Typography>
								</TableCell>
							</TableRow>
						))
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
