import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Box, Button, Card, CardContent, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../../context";
import { useProfile } from "../../../context/ProfileContext";
import { FerramentaDetalhe, ToastContent } from "../../../shared/components";
import { PermissionActionsEnum, PermissionRolesEnum } from "../../../shared/enums/profiles";
import { IVFormErros, useVForm, VForm } from "../../../shared/forms";
import { checkByProfileGuard } from "../../../shared/guards/ProfileGuard";
import { useToast } from "../../../shared/hooks";
import { useClientDetails } from "../../../shared/hooks/useClientDetails";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { clientService } from "../../../shared/services/api/client/clientService";
import { globalRemoveMaskReal } from "../../../shared/utils";
import { returnMessageError } from "../../../shared/utils/returnMessageError";
import { resetAddressFields } from "../../../shared/utils/utilsAddress";
import { getFollowUpServiceByContext } from "./ModalFollowUpGeneric/FollowUpService";
import { GenericFollowUpModalContent } from "./ModalFollowUpGeneric/GenericFollowUpModalContent";
import { AddressClient } from "./createComponents/AddressClient";
import { BankClient } from "./createComponents/BankClient";
import { ClientRelationsTabs } from "./createComponents/ClientRelationsTabs";
import { GenerateClient } from "./createComponents/GenerateClient";
import { ModalLogs } from "./createComponents/Modals/ModalLogs";
import { ParamsClient } from "./createComponents/Parametrizes";
import { getClientValidationSchema, IFormData } from "./createComponents/clientValidationSchema";

export const DetalheClientesPresentation: React.FC = () => {
	const { id = "novo" } = useParams<"id">();
	const navigate = useNavigate();
	const { formRef, save, saveAndClose, issaveAndClose } = useVForm();
	const [nome, setNome] = useState("");
	const [clientType, setClientType] = useState("legal");
	const toastHandler = useToast();
	const { isSuperAdmin, permissionLevel } = useProfile();
	const { loggedUser } = useAuthContext();
	const [openModalLogs, setOpenModalLogs] = useState(false);
	const [openModalFollowUp, setOpenModalFollowUp] = useState(false);
	const [activeSignature, setActiveSignature] = useState(false);
	const [signContract, setSignContract] = useState(true);
	const [activeSetup, setActiveSetup] = useState(false);
	const [bonusActive, setBonusActive] = useState(false);
	const [comissionActive, setComissionActive] = useState(false);
	const [baseRateCalc, setBaseRateCalc] = useState("aluguel");
	const [creditCard, setCreditCard] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const followUpServices = getFollowUpServiceByContext("client");

	const { isLoading: isLoadingClientData, row: clientData } = useClientDetails({
		id,
		formRef,
		setNome,
		setInitialStates: (data) => {
			setActiveSignature(data.activeSignature === 1);
			setSignContract(data.signContract === "sign");
			setActiveSetup(data.tax_setup_enable === 1);
			setBonusActive(data.bonusActive === "active");
			setComissionActive(data.comissionActive === "active");
			setCreditCard(data.creditCard === "active");
		},
	});

	useEffect(() => {
		if (id === "novo") {
			formRef.current?.setData({
				name: "",
				responsible_name: "",
				responsible_document: "",
				type_person: "legal",
				type: "",
				document: " ",
				trade_name: "",
				creci: "",
				phone: " ",
				mobilePhone: "",
				email: "",
				address_zipcode: " ",
				address_street: "",
				address_number: "",
				typecomplement: "",
				address_complement: "",
				address_district: "",
				address_city: "",
				address_state: "",
				status: "active",
				created_from: "web",
				invoice_due_date: "10",
				invoiceGateway: "SICOOB",
				cadastro: new Date(),
				pix_key: "",
				agency_number: "",
				agency_digit: "",
				account_number: "",
				account_digit: "",
				bank_manager: "",
				observationPartner: "",
				observation: "",
				creditCard: "inactive",
				bonusActive: "inactive",
				comissionActive: "inactive",
				tax_setup_enable: "0",
				tax_setup_limit_default: "0",
				tax_setup_limit_max: "0",
				tax_setup_limit_min: "0",
			});
		}
	}, [id]);

	const handleClientType = (e: any) => {
		setClientType(e.target.value);
		formRef.current?.setFieldValue("document", " ");
	};

	function useClientPermissions() {
		const { loggedUser } = useAuthContext();
		console.log("loggedUser", loggedUser);
		return {
			canEdit: checkByProfileGuard(PermissionRolesEnum.ClientsOptions, PermissionActionsEnum.Update),
			canAdd: checkByProfileGuard(PermissionRolesEnum.ClientsOptions, PermissionActionsEnum.Add),
			canViewLogs: loggedUser?.role === "admin",
		};
	}

	const { canEdit, canAdd, canViewLogs } = useClientPermissions();

	const checkCEP = (e: any) => {
		const cep = e.target.value.replace(/\D/g, "");
		if (cep.length !== 8) {
			resetAddressFields(formRef, cep);
			toastHandler.present({
				type: "error",
				position: "top-right",
				messageOrContent: "CEP não encontrado!",
			});
		} else {
			fetch(`https://viacep.com.br/ws/${cep}/json/`)
				.then((res) => res.json())
				.then((data) => {
					if (data.hasOwnProperty("erro")) {
						resetAddressFields(formRef, cep);
						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: "CEP não encontrado!",
						});
					} else {
						formRef.current?.setFieldValue("address_zipcode", data.cep);
						formRef.current?.setFieldValue("address_street", data.logradouro);
						formRef.current?.setFieldValue("residence_typeo", "");
						formRef.current?.setFieldValue("address_district", data.bairro);
						formRef.current?.setFieldValue("address_city", data.localidade);
						formRef.current?.setFieldValue("address_state", data.uf);
						formRef.current?.setFieldValue("address_ibge", data.ibge);
					}
				});
		}
	};

	const handleSave = async (dados: IFormData) => {
		try {
			setIsLoading(true);
			formRef.current?.setErrors({});

			// Sanitização
			dados.document = dados.document.replace(/[^a-zA-Z0-9\s]/g, "");
			dados.phone = dados.phone.replace(/[^0-9]/g, "");
			dados.mobilePhone = dados.mobilePhone ? dados.mobilePhone.replace(/[^0-9]/g, "") : "";
			dados.observationPartner = !dados.observationPartner ? "" : dados.observationPartner;
			delete dados.origem;

			dados.baseRateCalc = "aluguel";
			dados.activeSignature = activeSignature;
			dados.signContract = signContract === false ? "not_sign" : "sign";
			dados.tax_setup_enable = activeSetup;
			dados.bonusActive = bonusActive === false ? "inactive" : "active";
			dados.comissionActive = comissionActive === false ? "inactive" : "active";
			dados.creditCard = creditCard === false ? "inactive" : "active";
			dados.tax_setup_limit_default = dados.tax_setup_limit_default ? globalRemoveMaskReal(dados.tax_setup_limit_default) : "0";
			dados.tax_setup_limit_max = dados.tax_setup_limit_max ? globalRemoveMaskReal(dados.tax_setup_limit_max) : "0";
			dados.tax_setup_limit_min = dados.tax_setup_limit_min ? globalRemoveMaskReal(dados.tax_setup_limit_min) : "0";

			// Validação
			const formValidationSchema = getClientValidationSchema(id === "novo");
			const dadosvalidados = await formValidationSchema.validate(dados, { abortEarly: false });

			// Criação ou atualização
			if (id === "novo") {
				const result = await clientService.create(dadosvalidados);
				if (issaveAndClose()) navigate("/clientes");
				else navigate(`/clientes/detalhe/${result}`);
			} else {
				const result = await clientService.updateById(id, { id, ...dadosvalidados });

				if (result instanceof Error) {
					throw result;
				} else {
					if (issaveAndClose()) navigate("/clientes");

					toastHandler.present({
						type: "success",
						position: "top-right",
						messageOrContent: <ToastContent title="Sucesso" content="Cliente salvo com sucesso!" />,
					});
				}
			}
		} catch (err: any) {
			if (err?.name === "ValidationError") {
				const validationErros: IVFormErros = {};
				err.inner.forEach((error: any) => {
					if (!error.path) return;
					validationErros[error.path] = error.message;
				});
				formRef.current?.setErrors(validationErros);
			}
			const { titleMessage, contentMessage } = returnMessageError(err);
			toastHandler.present({
				type: "error",
				position: "top-right",
				messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<LayoutBaseDePagina
			titulo="Clientes"
			subtitulo={id === "novo" ? "Cadastro de novo cliente" : `Alteração de cliente [ ${nome} ]`}
			barraDeFerramentas={
				<FerramentaDetalhe
					textoButtonNew="novo"
					showBotaoApagar={false}
					showBotaoSalvar={!isLoading && canEdit}
					showBotaoSalvarFechar={!isLoading && canEdit}
					showButtonNew={id !== "novo" && canAdd}
					showButtonFollowUp={!isLoading && isSuperAdmin}
					clicarSalvar={save}
					clicarSalvarFechar={saveAndClose}
					clicarNovo={() => navigate("/clientes/detalhe/novo")}
					clicarVoltar={() => navigate("/clientes")}
					clickButtonFollowUp={() => setOpenModalFollowUp(true)}
				/>
			}
		>
			<Box sx={{ width: "100%", maxWidth: "1400px", px: 2 }}>
				<VForm ref={formRef} onSubmit={handleSave} onPointerEnterCapture={""} onPointerLeaveCapture={""} placeholder={""}>
					<Card sx={{ mb: 3 }}>
						<CardContent>
							<GenerateClient ClientType={clientType} handleClientType={handleClientType} isLoading={isLoading} setNome={setNome} />
							<Divider sx={{ my: 2 }} />
							<BankClient isLoading={isLoading} />
							<Divider sx={{ my: 2 }} />
							<AddressClient checkCEP={checkCEP} isLoading={isLoading} />
							<Divider sx={{ my: 2 }} />
							<ClientRelationsTabs id={id} isSuperAdmin={isSuperAdmin} permissionLevel={permissionLevel || 1000} isLoading={isLoading} />
							<Divider sx={{ my: 2 }} />
							<ParamsClient
								baseRateCalc={baseRateCalc}
								setBaseRateCalc={setBaseRateCalc}
								isLoading={isLoading}
								isSuperAdmin={isSuperAdmin}
								activeSignature={activeSignature}
								setActiveSignature={setActiveSignature}
								signContract={signContract}
								setSignContract={setSignContract}
								permissionLevel={permissionLevel}
								activeSetup={activeSetup}
								setActiveSetup={setActiveSetup}
								setBonusActive={setBonusActive}
								setComissionActive={setComissionActive}
								bonusActive={bonusActive}
								comissionActive={comissionActive}
								creditCard={creditCard}
								setCreditCard={setCreditCard}
							/>
						</CardContent>
					</Card>

					{loggedUser?.role === "admin" && (
						<Card sx={{ mb: 2 }}>
							<CardContent>
								<Box display="flex" justifyContent="space-between" alignItems="center">
									<Typography variant="h6" fontWeight={400} color="secondary">
										Logs do Cliente
									</Typography>
									<Button variant="outlined" color="info" startIcon={<RemoveRedEyeOutlinedIcon />} onClick={() => setOpenModalLogs(true)}>
										Visualizar Logs
									</Button>
								</Box>
							</CardContent>
						</Card>
					)}
				</VForm>
			</Box>

			{canViewLogs && <ModalLogs isOpen={openModalLogs} onClose={() => setOpenModalLogs(false)} id={id} />}

			{openModalFollowUp && (
				<GenericFollowUpModalContent
					open={openModalFollowUp}
					onClose={() => setOpenModalFollowUp(false)}
					clientId={id}
					loggedUser={loggedUser!}
					services={followUpServices}
					permissionSend={true}
					permissionAddContact={true}
				/>
			)}
		</LayoutBaseDePagina>
	);
};
