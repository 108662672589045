import { ClientType } from "../../../../../client_api/model/clientType";
import { Environment } from "../../../environment";
import { Logs } from "../../../interfaces";
import { Api } from "../axios-config";
import { AxiosError } from "axios";

export interface IListagemCliente {
	id: string;
	document: string;
	status: string;
	financial_email: string;
	creci: string;
	name: string;
	email?: string;
	trade_name: string;
	responsible_name: string;
	responsible_document: string;
	type_person: string;
	phone: string;
	address_zipcode: string;
	address_street: string;
	address_number: string;
	address_complement: string;
	address_district: string;
	address_city: string;
	address_state: string;
	wallet_properties_count: number;
	accept_terms_service: number;
	createdAt: string;
	bank_code: string;
	residential_fee: string;
	commercial_fee: string;
	mobilePhone: string;
	address_ibge: string;
	created_from: string;
	partnerId: string;
	lmi: number;
	clientType: ClientType;
	bank_manager?: string;
	invoice_due_date: number;
	observationPartner?: string;
	activeSignature?: number | boolean;
	activeSetup?: number | boolean;
}

export interface IDetalheCliente {
	id: string;
	document: string;
	type_person?: string;
	status?: string;
	email?: string;
	creci?: string;
	name: string;
	trade_name?: string;
	responsible_name?: string;
	responsible_document?: string;
	typeId?: string;
	phone?: string;
	address_zipcode?: string;
	address_street?: string;
	address_number?: string;
	address_complement?: string;
	address_district?: string;
	address_city?: string;
	address_state?: string;
	wallet_properties_count?: number;
	accept_terms_service?: number;
	createdAt?: Date;
	created_at?: Date;
	updated_at?: Date;
	brokerId?: string;
	residential_fee?: string;
	commercial_fee?: string;
	mobilePhone?: string;
	address_ibge?: string;
	created_from?: string;
	partnerId?: string;
	lmi?: number;
	invoice_due_date?: number;
	invoiceGateway?: string;
	bank_code?: string;
	managerId?: string;
	broker_amount?: string;
	manager_amount?: string;
	due_data?: number;
	agency_number?: string;
	agency_digit?: string;
	account_number?: string;
	account_digit?: string;
	pix_key?: string;
	pix_key_type?: string;
	bank_manager?: string;
	baseRateCalc?: string;
	activeSignature?: number | boolean;
	signContract?: string;
	tax_setup_enable?: number | boolean;
	__client_plan__?: {
		id: string;
		status: string;
		clientId: string;
		planId: string;
		productId: string;
		lmi: string;
		residentialFee: string;
		commercialFee: string;
		bonusAmount: string;
		flagAnalysis: string;
		flagRenovation: string;
		created_at: string;
		updated_at: string;
	};
	observationPartner?: string;
	bonusActive?: string;
	comissionActive?: string;
	creditCard?: string;
}

type TclientTotalCount = {
	data: IListagemCliente[];
	totalCount: number;
};

export type TClientData = {
	data: IListagemCliente[];
};

type TclientData = {
	data: IListagemCliente[];
};

export interface IPlanRelation {
	id: string;
	description?: string;
	lmi: string;
	residentialFee: string;
	commercialFee: string;
	bonusAmount: string;
	status: string;
	productId: string;
	clientId?: string;
	flagAnalysis: string;
	flagRenovation: string;
	flagRateRent: string;
	flagRateCharge: string;
	coverage: string;
	coverageAcessories:string;
	setupTransfer: string;
	usedFor?: string;
	comissionProposalMin?: string;
	chargeType?: string;
	comissionValue: string;
}

export interface IContactsClient {
	contactName: string;
	contactEmail: string;
	contactPhone?: string;
	//context?:string[];
	contactType?: string[];
	clientId: string;
	client: {
		name: string;
		email: string;
		document: string;
	};
}

export interface ICreateContactsClient {
	contactName: string;
	contactEmail: string;
	contactPhone?: string;
	contactType?: string[];
	clientId: string;
	status: string;
}

export interface IUpdateContactsClient {
	id: string;
	contactName: string;
	contactEmail: string;
	contactPhone?: string;

	contactType?: string[];
	clientId: string;
	status: string;
}

export interface IGroupCliente {
	description: string;
	total: string;
}

type TGroupClienteTotal = {
	data: IGroupCliente[];
	totalCount: number;
};

const getAll = async (
	page = 1,
	filter = "",
	tipocliente = "",
	origemcliente = "",
	statuscliente = "",
	clienteFilter = "",
	dataini = "",
	datafim = "",
	uf = "",
	managerId = ""
): Promise<TclientTotalCount | Error> => {
	try {
		const urlRelativa = `/client?_page=${page}&_limit=${Environment.LIMITE_LINHAS}&nome_like=${filter}&tipo=${tipocliente}&origem=${origemcliente}&status=${statuscliente}&clienteFilter=${clienteFilter}&dataini=${dataini}&datafim=${datafim}&uf=${uf}&managerId=${managerId}`;

		const { data, headers } = await Api().get(urlRelativa);
		if (data.results) {
			return {
				data: data.results,
				totalCount: data.total,
			};
		}
		return new Promise((_, reject) => reject(new Error("Erro ao listar registros.")));
	} catch (error) {
		console.error(error);
		throw new Promise((_, reject) => reject(error));
	}
};

const getSelect = async (
	page = 1,
	filter = "",
	tipocliente = "",
	origemcliente = "",
	statuscliente = "",
	clienteFilter = ""
): Promise<TclientTotalCount | Error> => {
	try {
		const urlRelativa = `/client?_page=${page}&_limit=${Environment.LIMITE_LINHAS}&nome_like=${filter}&tipo=${tipocliente}&origem=${origemcliente}&status=${statuscliente}&clientId=${clienteFilter}`;
		 console.log("urlRelativa" + urlRelativa);
		const { data, headers } = await Api().get(urlRelativa);
		if (data.results) {
			return {
				data: data.results,
				totalCount: data.total,
			};
		}
		return new Error("Erro ao listar registros.");
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const getAllGrafico = async (page = 1, filter = "", tipocliente = ""): Promise<TGroupClienteTotal | Error> => {
	try {
		//  olhar if do leandro aqui ********************************
		const urlRelativa = `/client/clientgroup`;
		const { data, headers } = await Api().get(urlRelativa);

		if (data.results) {
			return {
				data: data.results,
				totalCount: data.total,
			};
		}
		return new Error("Erro ao listar registros.");
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const getByID = async (id: string): Promise<IDetalheCliente | Error> => {
	try {
		const { data } = await Api().get(`/client/${id}`);
		if (data) return data;

		return new Promise((_, reject) => reject(new Error("Erro ao listar registros.")));
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const getClientContractByID = async (id: string): Promise<IDetalheCliente | Error> => {
	try {
		const { data } = await Api().get(`/client-contract/${id}`);
		if (data) return data;

		return new Promise((_, reject) => reject(new Error("Erro ao listar registros.")));
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const getContactsById = async (id: string): Promise<IContactsClient[] | Error> => {
	try {
		const { data } = await Api().get(`/cliente-contato/cliente/${id}`);
		console.log(data);
		if (data) return data;

		return new Promise((_, reject) => reject(new Error("Erro ao listar registros.")));
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const createContact = async (dados: ICreateContactsClient): Promise<IContactsClient | Error> => {
	try {
		const { data } = await Api().post<IContactsClient>("/cliente-contato", {
			...dados,
			contactType: dados.contactType?.join(","),
			status: "active",
		});
		if (data) return data;

		return new Promise((_, reject) => reject(new Error("Erro ao criar o registro.")));
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const updateContact = async (dados: IUpdateContactsClient): Promise<IContactsClient | Error> => {
	try {
		const { data } = await Api().patch<IContactsClient>(`/cliente-contato/${dados.id}`, { ...dados, contactType: dados.contactType?.join(",") });
		return data;
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const create = async (dados: Omit<IDetalheCliente, "id">): Promise<string> => {
	try {
		const { data } = await Api().post<IDetalheCliente>("/client", dados);

		return data.id;
	} catch (error) {
		if (error instanceof AxiosError) {
			if (error?.response?.data?.message && typeof error?.response?.data?.message === "string") {
				throw new Error(error.response.data.message);
			}
		}

		if (error instanceof Error) {
			throw new Error(error.message || "Erro ao criar o registro.");
		}

		throw new Error("Erro ao criar o registro.");
	}
};

const createPlanRelation = async (dados: Omit<IPlanRelation, "id">): Promise<string | Error> => {
	try {
		const { data } = await Api().post<IPlanRelation>("/client-plan", dados);
		if (data) return data.id;

		return new Promise((_, reject) => reject(new Error("Erro ao criar o registro.")));
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const updatePlanRelation = async (id: string, dados: IPlanRelation): Promise<string | Error> => {
	try {
		const { data } = await Api().patch<IPlanRelation>(`/client-plan/${id}`, dados);
		if (data) return data.id;

		return new Promise((_, reject) => reject(new Error("Erro ao criar o registro.")));
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const resendContract = async (id: string): Promise<string | Error> => {
	try {
		const { data } = await Api().get(`/contract/resend/${id}`);
		if (data) return data.id;

		return new Promise((_, reject) => reject(new Error("Erro ao criar o registro.")));
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const getAllNotExists = async (client = "", filter = ""): Promise<TclientTotalCount | Error> => {
	try {
		const urlRelativa = `/client-relation/plan-not-exists/${client}?nome_like=${filter}`;
		const { data, headers } = await Api().get(urlRelativa);

		if (data.results) {
			return {
				data: data.results,
				totalCount: data.total,
			};
		}
		return new Error("Erro ao listar registros.");
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao listar registros.");
	}
};

const updateById = async (id: string, dados: IDetalheCliente): Promise<void | Error> => {
	try {
		await Api().patch(`/client/${id}`, dados);
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const deleteById = async (id: string): Promise<void | Error> => {
	try {
		await Api().delete(`/client/${id}`);
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const getPartners = async (clientId: string): Promise<TclientData | Error> => {
	try {
		const urlRelativa = `/client/partner/client/partners?clientId=${clientId}`;

		const { data } = await Api().get(urlRelativa);

		if (data.results) {
			return {
				data: data.results,
			};
		}

		return new Promise((_, reject) => reject(new Error("Erro ao listar registros de parceiros.")));
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const getLogs = async (clientId: string): Promise<Logs[] | Error> => {
	try {
		const { data } = await Api().get(`/logs/${clientId}`);

		if (!data) return new Promise((_, reject) => reject(new Error("Erro ao consultar os registros de logs do cliente.")));

		return data;
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

export const clientService = {
	getAll,
	resendContract,
	getSelect,
	getAllGrafico,
	getByID,
	getContactsById,
	createContact,
	updateContact,
	create,
	updateById,
	deleteById,
	createPlanRelation,
	updatePlanRelation,
	getPartners,
	getLogs,
	getClientContractByID
};
