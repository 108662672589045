export function resetAddressFields(formRef: any, cep: string) {
	formRef.current?.setFieldValue("address_zipcode", cep);
	formRef.current?.setFieldValue("address_street", "");
	formRef.current?.setFieldValue("residence_typeo", "");
	formRef.current?.setFieldValue("address_number", "");
	formRef.current?.setFieldValue("address_complement", "");
	formRef.current?.setFieldValue("address_district", "");
	formRef.current?.setFieldValue("address_city", "");
	formRef.current?.setFieldValue("address_state", "");
	formRef.current?.setFieldValue("address_ibge", "3113404");
}
