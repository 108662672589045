import React, { useEffect, useState, useRef, useCallback } from "react";
import {
	Box,
	CircularProgress,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
	Fade,
	Tooltip,
	Dialog,
	DialogTitle,
	DialogContent,
	MenuItem,
	Select,
	FormControl,
	InputLabel,
	Modal,
	Backdrop,
} from "@mui/material";
import { DescriptionOutlined as DescriptionIcon, Close as CloseIcon, VisibilityOutlined as EyeIcon, ContentCopy as CopyIcon } from "@mui/icons-material";
import dayjs from "dayjs";
import { ToastContent } from "../../../../../shared/components";
import { Logs } from "../../../../../shared/interfaces";
import { returnMessageError } from "../../../../../shared/utils/returnMessageError";
import { useToast } from "../../../../../shared/hooks";
import { clientService } from "../../../../../shared/services/api/client/clientService";

interface ModalLogsProps {
	isOpen: boolean;
	onClose: () => void;
	id: string;
}

const PAGE_SIZE = 25;

export const ModalLogs: React.FC<ModalLogsProps> = ({ isOpen, onClose, id }) => {
	const [allLogs, setAllLogs] = useState<Logs[]>([]);
	const [filteredLogs, setFilteredLogs] = useState<Logs[]>([]);
	const [visibleLogs, setVisibleLogs] = useState<Logs[]>([]);
	const [eventTypes, setEventTypes] = useState<string[]>([]);
	const [userNames, setUserNames] = useState<string[]>([]);
	const [selectedEventType, setSelectedEventType] = useState<string>("todos");
	const [selectedUser, setSelectedUser] = useState<string>("todos");
	const [isLoading, setIsLoading] = useState(false);
	const [openPayload, setOpenPayload] = useState(false);
	const [payloadContent, setPayloadContent] = useState("");
	const [payloadId, setPayloadId] = useState<string>("");
	const loaderRef = useRef<HTMLDivElement>(null);
	const toast = useToast();

	const handleCopy = (content: string) => {
		navigator.clipboard.writeText(content);
		toast.present({
			type: "success",
			position: "top-right",
			messageOrContent: <ToastContent title="Copiado!" content="Payload copiado." />,
		});
	};

	const handleOpenPayload = (payload: any, id: string) => {
		const formatted = JSON.stringify(payload, null, 2);
		setPayloadContent(formatted);
		setPayloadId(id);
		setOpenPayload(true);
	};

	const handleClosePayload = () => {
		setOpenPayload(false);
		setPayloadContent("");
		setPayloadId("");
	};

	const applyFilters = useCallback(() => {
		let result = [...allLogs];

		if (selectedEventType !== "todos") {
			result = result.filter((log) => log.eventType === selectedEventType);
		}

		if (selectedUser !== "todos") {
			result = result.filter((log) => log.userName === selectedUser);
		}

		result.sort((a, b) => dayjs(b.createdDate).unix() - dayjs(a.createdDate).unix());

		setFilteredLogs(result);
		setVisibleLogs(result.slice(0, PAGE_SIZE));
	}, [allLogs, selectedEventType, selectedUser]);

	const handleFilterChange = (type: string, value: string) => {
		if (type === "event") setSelectedEventType(value);
		if (type === "user") setSelectedUser(value);
	};

	useEffect(() => {
		applyFilters();
	}, [selectedEventType, selectedUser, applyFilters]);

	useEffect(() => {
		if (!isOpen) return;

		const fetchLogs = async () => {
			setIsLoading(true);
			try {
				const result = await clientService.getLogs(id);
				if (result instanceof Error) throw result;

				const ordered = result.sort((a, b) => dayjs(b.createdDate).unix() - dayjs(a.createdDate).unix());
				const types = Array.from(new Set(result.map((log) => log.eventType).filter(Boolean)));
				const users = Array.from(new Set(result.map((log: Logs) => log.userName).filter((name): name is string => typeof name === "string")));

				setEventTypes(types);
				setUserNames(users);
				setAllLogs(ordered);
				setFilteredLogs(ordered);
				setVisibleLogs(ordered.slice(0, PAGE_SIZE));
				setSelectedEventType("todos");
				setSelectedUser("todos");
			} catch (err) {
				const { titleMessage, contentMessage } = returnMessageError(err);
				toast.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
				});
			} finally {
				setIsLoading(false);
			}
		};

		fetchLogs();
	}, [isOpen, id]);

	const loadMoreLogs = useCallback(() => {
		setVisibleLogs((prev) => {
			const next = filteredLogs.slice(prev.length, prev.length + PAGE_SIZE);
			return [...prev, ...next];
		});
	}, [filteredLogs]);

	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			if (entries[0].isIntersecting) loadMoreLogs();
		});
		if (loaderRef.current) observer.observe(loaderRef.current);
		return () => observer.disconnect();
	}, [loadMoreLogs]);

	return (
		<>
			<Modal open={isOpen} onClose={onClose} closeAfterTransition slots={{ backdrop: Backdrop }} slotProps={{ backdrop: { timeout: 500 } }}>
				<Fade in={isOpen}>
					<Box
						sx={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							width: "70%",
							maxHeight: "90vh",
							bgcolor: "background.paper",
							borderRadius: 2,
							boxShadow: 24,
							p: 3,
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Box sx={{ position: "sticky", top: 0, zIndex: 2, backgroundColor: "white", py: 1, borderBottom: "1px solid #e0e0e0" }}>
							<Box display="flex" justifyContent="space-between" alignItems="center">
								<Box display="flex" alignItems="center" gap={2}>
									<DescriptionIcon sx={{ fontSize: 30, color: "primary.main" }} />
									<Typography variant="h5" fontWeight={400} color="primary.main">
										Logs do Cliente ({filteredLogs.length} {filteredLogs.length === 1 ? "registro" : "registros"})
									</Typography>
								</Box>
								<IconButton onClick={onClose}>
									<CloseIcon />
								</IconButton>
							</Box>

							<Box mt={2} display="flex" gap={2} flexWrap="wrap">
								<FormControl size="small" sx={{ minWidth: 180 }}>
									<InputLabel>Evento</InputLabel>
									<Select value={selectedEventType} label="Evento" onChange={(e) => handleFilterChange("event", e.target.value)}>
										<MenuItem value="todos">Todos</MenuItem>
										{eventTypes.map((type) => (
											<MenuItem key={type} value={type}>
												{type}
											</MenuItem>
										))}
									</Select>
								</FormControl>

								<FormControl size="small" sx={{ minWidth: 180 }}>
									<InputLabel>Usuário</InputLabel>
									<Select value={selectedUser} label="Usuário" onChange={(e) => handleFilterChange("user", e.target.value)}>
										<MenuItem value="todos">Todos</MenuItem>
										{userNames.map((name) => (
											<MenuItem key={name} value={name}>
												{name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Box>
						</Box>

						<Box sx={{ mt: 2, flex: 1 }}>
							{isLoading ? (
								<Box display="flex" justifyContent="center" py={4}>
									<CircularProgress />
								</Box>
							) : (
								<TableContainer component={Paper} elevation={3} sx={{ borderRadius: 3, maxHeight: "60vh", overflow: "auto" }}>
									<Table size="small">
										<TableBody>
											<TableRow sx={{ backgroundColor: "#f5f5f5" }}>
												{["Data Criada", "Tipo Evento", "Nome Tabela", "Usuário ID", "Usuário Nome", "Payload"].map(
													(label) => (
														<TableCell key={label} sx={{ fontWeight: 600, color: "#333" }}>
															{label}
														</TableCell>
													)
												)}
											</TableRow>
											{visibleLogs.map((log, idx) => {
												const payloadStr = JSON.stringify(log.payload, null, 2);
												return (
													<TableRow
														key={idx}
														sx={{ backgroundColor: idx % 2 === 0 ? "#fff" : "#fafafa", "&:hover": { backgroundColor: "#f0f8ff" } }}
													>
														<TableCell>{dayjs(log.createdDate).format("DD/MM/YYYY HH:mm")}</TableCell>
														<TableCell>{log.eventType}</TableCell>
														<TableCell>{log.tableName}</TableCell>
														<TableCell>{log.userId}</TableCell>
														<TableCell>{log.userName}</TableCell>
														<TableCell>
															<Box display="flex" gap={1}>
																<Tooltip title="Visualizar Payload">
																	<IconButton size="small" onClick={() => handleOpenPayload(log.payload, log._id)}>
																		<EyeIcon fontSize="small" />
																	</IconButton>
																</Tooltip>
																<Tooltip title="Copiar Payload">
																	<IconButton size="small" onClick={() => handleCopy(payloadStr)}>
																		<CopyIcon fontSize="small" />
																	</IconButton>
																</Tooltip>
															</Box>
														</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
									<Box ref={loaderRef} height={50} textAlign="center" py={2}>
										{visibleLogs.length < filteredLogs.length && <CircularProgress size={20} />}
									</Box>
								</TableContainer>
							)}
						</Box>
					</Box>
				</Fade>
			</Modal>

			<Dialog open={openPayload} onClose={handleClosePayload} maxWidth="md" fullWidth>
				<DialogTitle>
					Payload: {payloadId}
					<IconButton onClick={handleClosePayload} sx={{ position: "absolute", top: 10, right: 10 }}>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<Box
						sx={{
							backgroundColor: "#1e1e1e",
							color: "#d6e6ff",
							fontFamily: "monospace",
							p: 2,
							borderRadius: 2,
							whiteSpace: "pre-wrap",
							fontSize: 14,
							maxHeight: "60vh",
							overflow: "auto",
						}}
					>
						{payloadContent}
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
};
