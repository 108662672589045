import { Grid } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Chip,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { ToastContent } from "../../../../shared/components";
import { useVForm, VForm, VTextField } from "../../../../shared/forms";
import { useToast } from "../../../../shared/hooks";
import { returnMessageErrorAsync } from "../../../../shared/utils/returnMessageError";
import { FollowUpMessageCard } from "./FollowUpMessageCard";
import { isToday, isYesterday, format } from "date-fns";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export interface FollowUpServices {
	getNotes: (id: string) => Promise<any[]>;
	addNote: (id: string, message: string) => Promise<void>;
	getContacts: (id: string) => Promise<any[]>;
	addContact: (id: string, email: string) => Promise<void>;
	removeContact: (contactId: string) => Promise<void>;
}

interface Props {
	clientId: string;
	open: boolean;
	onClose: () => void;
	loggedUser: {
		id: string;
		name: string;
		email: string;
		role: string;
		permission_description: string;
	};
	services: FollowUpServices;
	permissionSend?: Boolean;
	permissionAddContact?: Boolean;
}

const addContactSchema = yup.object().shape({
	email: yup.string().email().required(),
});

export const GenericFollowUpModalContent = ({ clientId, open, onClose, loggedUser, services, permissionSend, permissionAddContact }: Props) => {
	const [allNotes, setAllNotes] = useState<any[]>([]);
	const [visibleNotes, setVisibleNotes] = useState<any[]>([]);
	const [visibleCount, setVisibleCount] = useState(10);
	const [contacts, setContacts] = useState<any[]>([]);
	const [loadingNotes, setLoadingNotes] = useState(false);
	const [loadingSend, setLoadingSend] = useState(false);
	const [search, setSearch] = useState("");
	const [editorState, setEditorState] = useState(EditorState.createEmpty());

	const scrollContainerRef = useRef<HTMLDivElement>(null);
	const scrollRef = useRef<HTMLDivElement>(null);
	const { formRef } = useVForm();
	const toast = useToast();
	const limit = 10;

	const cleanMessageHtml = (html: string) => {
		return html
			.replace(/^(<p><br><\/p>\s*)+/, "")
			.replace(/(\s*<p><br><\/p>)+$/, "")
			.replace(/(<p><br><\/p>\s*){2,}/g, "<p><br></p>")
			.trim();
	};

	const isEmptyContent = (state: EditorState) => {
		const raw = convertToRaw(state.getCurrentContent());
		const html = draftToHtml(raw).trim();
		const cleaned = cleanMessageHtml(html);
		return !cleaned || cleaned === "<p></p>" || cleaned === "<p><br></p>" || cleaned.replace(/<[^>]+>/g, "").trim() === "";
	};

	const isSendDisabled = loadingSend || isEmptyContent(editorState);

	useEffect(() => {
		const container = scrollContainerRef.current;
		const handleScroll = () => {
			if (container && container.scrollTop === 0 && visibleCount < allNotes.length) {
				const newCount = Math.min(visibleCount + limit, allNotes.length);
				const start = allNotes.length - newCount;
				const prevHeight = container.scrollHeight;

				setVisibleNotes(allNotes.slice(start));
				setVisibleCount(newCount);

				requestAnimationFrame(() => {
					container.scrollTop = container.scrollHeight - prevHeight;
				});
			}
		};

		container?.addEventListener("scroll", handleScroll);
		return () => container?.removeEventListener("scroll", handleScroll);
	}, [allNotes, visibleCount]);

	const fetchNotes = async () => {
		setLoadingNotes(true);
		try {
			const data = await services.getNotes(clientId);
			const sorted = data.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
			setAllNotes(sorted);
			setVisibleNotes(sorted.slice(-limit));
			setVisibleCount(limit);

			setTimeout(() => {
				scrollRef.current?.scrollIntoView({ behavior: sorted.length > limit ? "auto" : "smooth" });
			}, 300);
		} catch (e) {
			const { titleMessage, contentMessage } = await returnMessageErrorAsync(e);
			toast.present({
				type: "error",
				position: "top-right",
				messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
			});
		} finally {
			setLoadingNotes(false);
		}
	};

	const handleSend = async () => {
		const rawContentState = convertToRaw(editorState.getCurrentContent());
		const htmlContent = draftToHtml(rawContentState).trim();
		const cleaned = cleanMessageHtml(htmlContent);
		const isEmpty = !cleaned || cleaned === "<p></p>" || cleaned === "<p><br></p>" || cleaned.replace(/<[^>]+>/g, "").trim() === "";
		if (isEmpty) return;
		setLoadingSend(true);
		try {
			await services.addNote(clientId, cleaned);
			setEditorState(EditorState.createEmpty());
			fetchNotes();
		} catch (e) {
			const { titleMessage, contentMessage } = await returnMessageErrorAsync(e);
			toast.present({
				type: "error",
				position: "top-right",
				messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
			});
		} finally {
			setLoadingSend(false);
		}
	};

	const fetchContacts = async () => {
		try {
			const data = await services.getContacts(clientId);
			setContacts(data);
		} catch (e) {
			const { titleMessage, contentMessage } = await returnMessageErrorAsync(e);
			toast.present({
				type: "error",
				position: "top-right",
				messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
			});
		}
	};

	const addContact = async ({ email }: { email: string }) => {
		try {
			await addContactSchema.validate({ email });
			await services.addContact(clientId, email);
			formRef.current?.setFieldValue("email", "");
			fetchContacts();
		} catch (e) {
			const { titleMessage, contentMessage } = await returnMessageErrorAsync(e);
			toast.present({
				type: "error",
				position: "top-right",
				messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
			});
		}
	};

	const removeContact = async (id: string) => {
		try {
			await services.removeContact(id);
			fetchContacts();
			toast.present({
				type: "success",
				position: "top-right",
				messageOrContent: "Contato removido com sucesso",
			});
		} catch (e) {
			const { titleMessage, contentMessage } = await returnMessageErrorAsync(e);
			toast.present({
				type: "error",
				position: "top-right",
				messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
			});
		}
	};



	const formatGroupLabel = (date: Date): string => {
		if (isToday(date)) return "Hoje";
		if (isYesterday(date)) return "Ontem";
		return format(date, "dd/MM/yyyy");
	};

	useEffect(() => {
		if (open) {
			fetchNotes();
			fetchContacts();
		}
	}, [open]);

	const filteredNotes = allNotes
		.filter((note) => note.note?.toLowerCase().includes(search.toLowerCase()) || note.userName?.toLowerCase().includes(search.toLowerCase()))
		.filter((note) => visibleNotes.some((vn) => vn.id === note.id));

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle>
				<Typography variant="h6" color="primary">
					FollowUp
				</Typography>
			</DialogTitle>
			<DialogContent sx={{ display: "flex", flexDirection: "column", height: "80vh", p: 0 }}>
				{/* Accordion de E-mails */}
				<Box sx={{ p: 2, borderBottom: "1px solid #eee" }}>
					<Accordion disableGutters>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography fontWeight={600}>E-mail de Notificação ({contacts.length})</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<VForm ref={formRef} onSubmit={addContact} onPointerEnterCapture={""} onPointerLeaveCapture={""} placeholder={""}>
								<Grid container spacing={2} alignItems="center">
									<Grid item xs={10}>
										<VTextField fullWidth name="email" label="E-mail" size="small" />
									</Grid>
									<Grid item xs={2}>
										<Button fullWidth type="submit" variant="contained">
											Adicionar
										</Button>
									</Grid>
								</Grid>
							</VForm>
							<Box mt={2} display="flex" flexWrap="wrap" gap={1}>
								{contacts.map((contact) => (
									<Chip
										key={contact.id}
										label={contact.email}
										variant="outlined"
										color="default"
										onDelete={() => removeContact(contact.id)}
										deleteIcon={<CloseIcon fontSize="small" />}
									/>
								))}
							</Box>
						</AccordionDetails>
					</Accordion>
				</Box>

				{/* Campo de busca */}
				<Box sx={{ px: 2, py: 1, borderBottom: "1px solid #eee", bgcolor: "#fafafa" }}>
					<TextField
						fullWidth
						size="small"
						variant="outlined"
						placeholder="Buscar por nome ou conteúdo da mensagem..."
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon fontSize="small" />
								</InputAdornment>
							),
							endAdornment: search && (
								<InputAdornment position="end">
									<IconButton size="small" onClick={() => setSearch("")}>
										<CloseIcon fontSize="small" />
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</Box>

				{/* Lista de mensagens */}
				<Box flex={1} overflow="auto" p={2} bgcolor="#f4f4f4" ref={scrollContainerRef}>
					{loadingNotes ? (
						<Box textAlign="center" mt={4}>
							<CircularProgress />
						</Box>
					) : (
						<Stack spacing={2}>
							{filteredNotes.map((note, index) => {
								const currentDate = new Date(note.createdAt);
								const currentLabel = formatGroupLabel(currentDate);
								const prevNote = filteredNotes[index - 1];
								const prevDateLabel = prevNote ? formatGroupLabel(new Date(prevNote.createdAt)) : null;
								const showLabel = index === 0 || currentLabel !== prevDateLabel;

								return (
									<Box key={note.id}>
										{showLabel && (
											<Box display={"flex"} justifyContent={"center"}>
												<Typography
													variant="caption"
													sx={{
														textAlign: "center",
														display: "block",
														color: "text.secondary",
														fontWeight: 500,
														mt: index === 0 ? 0 : 2,
														mb: 1,
														bgcolor: "white",
														borderRadius: 5,
														justifyContent: "center",
														width: "auto",
														padding: "2px 10px",
														boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
													}}
												>
													{currentLabel}
												</Typography>
											</Box>
										)}

										<FollowUpMessageCard note={note} isMine={note.userId === loggedUser.id} loggedUserName={loggedUser.name} />
									</Box>
								);
							})}
							<div ref={scrollRef} />
						</Stack>
					)}
				</Box>

				{/* Área de envio */}
				{permissionSend && (
					<Box sx={{ p: 2, borderTop: "1px solid #ddd", backgroundColor: "white" }}>
						<Editor
							editorState={editorState}
							onEditorStateChange={setEditorState}
							toolbar={{
								options: ["inline", "list", "blockType", "link"],
								inline: { options: ["bold", "italic", "underline"] },
								list: { options: ["unordered", "ordered"] },
								blockType: { options: ["Normal", "Blockquote", "Code"] },
							}}
							wrapperStyle={{ border: "1px solid #ccc", borderRadius: 4 }}
							editorStyle={{
								minHeight: 100,
								padding: 10,
								fontSize: "0.95rem",
								fontFamily: `'Inter', 'Roboto', 'Segoe UI', sans-serif`, // 👈 fonte moderna e adaptável
								lineHeight: 1.6,
							}}
						/>

						<Box display="flex" justifyContent="flex-end" mt={1}>
							<Button onClick={handleSend} variant="contained" disabled={isSendDisabled} endIcon={<SendIcon />}>
								Enviar
							</Button>
						</Box>
					</Box>
				)}
			</DialogContent>
		</Dialog>
	);
};
