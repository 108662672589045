import React, { useState } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { CommercialGridClient } from "../RelationComponents/Commercial";
import { ManagerClient } from "../RelationComponents/Manager";
import { PartnersCard } from "../RelationComponents/Partners";
import { ContactsClient } from "../RelationComponents/ContactsClient";
import Contracts from "../contractsComponents/Contracts";

interface ClientRelationsTabsProps {
	id: string;
	isSuperAdmin: boolean;
	permissionLevel: number;
	isLoading: boolean;
}

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;
	return (
		<div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
			{value === index && (
				<Box sx={{ py: 2 }}>
					<Typography component="div">{children}</Typography>
				</Box>
			)}
		</div>
	);
}

export const ClientRelationsTabs: React.FC<ClientRelationsTabsProps> = ({ id, isSuperAdmin, permissionLevel, isLoading }) => {
	const [tabValue, setTabValue] = useState(0);

	if (!(isSuperAdmin || permissionLevel === 2) || id === "novo") return null;

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
	};

	return (
		<Box mt={2}>
			<Box padding={2}>
				<Typography variant="h6" color={"primary"}>
					Relacionamento e comercial
				</Typography>

				<Tabs value={tabValue} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
					<Tab label="Grade Comercial" />
					<Tab label="Gestores de Contas" />
					<Tab label="Parceiros" />
					<Tab label="Contatos / Emails" />
					<Tab label="Contratos" />
				</Tabs>
			</Box>

			<TabPanel value={tabValue} index={0}>
				<CommercialGridClient id={id} isLoading={isLoading} />
			</TabPanel>

			<TabPanel value={tabValue} index={1}>
				<ManagerClient id={id} isLoading={isLoading} isSuperAdmin={isSuperAdmin} permissionLevel={permissionLevel} />
			</TabPanel>

			<TabPanel value={tabValue} index={2}>
				<PartnersCard id={id} />
			</TabPanel>

			<TabPanel value={tabValue} index={3}>
				<ContactsClient id={id} isLoading={isLoading} />
			</TabPanel>

			<TabPanel value={tabValue} index={4}>
				<Contracts clientId={id} />
			</TabPanel>
		</Box>
	);
};
